import { serverOnly$ } from 'vite-env-only/macros'
import { AppGlobals } from './AppGlobals'

type AppGlobalsType = {
  APP_ENV: string
  ADMIN_BASE_URL: string
  BOOKING_URL: string
  COOKIE_DOMAIN: string
  GEOLOCATION_URL: string
  SENTRY_DSN: string
  DATADOG_APPLICATION_ID?: string
  DATADOG_CLIENT_TOKEN?: string
  DATADOG_SERVICE?: string
  DATADOG_ENV?: string
  DATADOG_SESSION_REPLAY_SAMPLE_RATE?: string
  DATADOG_SESSION_SAMPLE_RATE?: string
  DATADOG_TRACE_SAMPLE_RATE?: string
  HOST?: string
  HIDE_BILLING_CONTENT: boolean
  AMPLITUDE_API_KEY?: string
  STRIPE_PUBLISHABLE_KEY: string
  GTM_MEASURE_ID?: string
}

export function getAppGlobals(): AppGlobalsType {
  return AppGlobals.get<AppGlobalsType>()
}

export const setGlobalData = serverOnly$(async () =>
  AppGlobals.set<AppGlobalsType>({
    APP_ENV: String(process.env.APP_ENV),
    ADMIN_BASE_URL: String(process.env.ADMIN_BASE_URL),
    BOOKING_URL: String(process.env.BOOKING_URL),
    COOKIE_DOMAIN: String(process.env.COOKIE_DOMAIN),
    GEOLOCATION_URL: String(process.env.GEOLOCATION_URL),
    SENTRY_DSN: String(process.env.SENTRY_DSN),
    DATADOG_APPLICATION_ID: process.env.DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE: process.env.DATADOG_SERVICE,
    DATADOG_ENV: process.env.DATADOG_ENV,
    DATADOG_SESSION_REPLAY_SAMPLE_RATE:
      process.env.DATADOG_SESSION_REPLAY_SAMPLE_RATE,
    DATADOG_SESSION_SAMPLE_RATE: process.env.DATADOG_SESSION_SAMPLE_RATE,
    DATADOG_TRACE_SAMPLE_RATE: process.env.DATADOG_TRACE_SAMPLE_RATE,
    HOST: process.env.HOST,
    HIDE_BILLING_CONTENT: process.env.HIDE_BILLING_CONTENT === 'true',
    AMPLITUDE_API_KEY: process.env.AMPLITUDE_API_KEY,
    STRIPE_PUBLISHABLE_KEY: String(process.env.STRIPE_PUBLISHABLE_KEY),
    GTM_MEASURE_ID: process.env.GTM_MEASURE_ID,
  }),
)
