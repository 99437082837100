/**
 * We need to do this otherwise we won't be able
 * to use data from the root loader inside the ErrorBoundary components.
 * Source: https://github.com/kiliman/remix-global-data
 */

let appGlobals: unknown = {}

declare global {
  interface Window {
    __AppGlobals__: unknown
  }
}

export const AppGlobals = {
  get: getAppGlobals,
  set: setAppGlobals,
}

function setAppGlobals<T>(globals: T) {
  appGlobals = globals
}

function getAppGlobals<T>(): T {
  if (typeof document !== 'undefined') {
    return window.__AppGlobals__ as T
  }
  return appGlobals as T
}

export function AppGlobalsScript() {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `window.__AppGlobals__=${JSON.stringify(getAppGlobals())};`,
      }}
    ></script>
  )
}
